import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import Gallery from 'react-photo-gallery';
import cloneDeep from 'lodash.clonedeep';
import { GooglePhoto } from '../src/index.tsx';
import '../styles.css';
import './examples/custom-animation.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "react-google-photo"
    }}>{`react-google-photo`}</h2>
    <p>{`react-google-photo is a responsive lightbox React Component for displaying photos that implements the google photo style.`}</p>
    <ul>
      <li parentName="ul">{`Responsive.`}</li>
      <li parentName="ul">{`Keyboard navigation.`}</li>
      <li parentName="ul">{`Easily customizable via props.`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`With npm:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install react-google-photo --save
`}</code></pre>
    <p>{`Or with yarn:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add react-google-photo
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import 'react-google-photo/styles.css';
import GooglePhoto from 'react-google-photo';

const App = () => {
  const [open, setOpen] = React.useState(false);

const images = [
    {
      src:
        'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
      width: 1500,
      height: 1000,
    },
    ...
  ];

  return (
    <div>
      <button onClick={this.handleOpen}>Open</button>
      <GooglePhoto
        open={open}
        src={images}
        onClose={this.handleClose}
      />
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={GooglePhoto} mdxType="Props" />
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Photos from the `}<a parentName="p" {...{
          "href": "https://unsplash.com/collections/589374/textures"
        }}>{`texture collection`}</a>{` on `}<a parentName="p" {...{
          "href": "https://unsplash.com/"
        }}>{`Unsplash`}</a>{`. The gallery is using the `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/react-photo-gallery"
        }}>{`react-photo-gallery`}</a>{` npm package.`}</p>
    </blockquote>
    <h3 {...{
      "id": "basic-example"
    }}>{`Basic example`}</h3>
    <Playground __position={1} __code={'() => {\n  // https://unsplash.com/collections/589374/textures\n  const images = [\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 666,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 844,\n    },\n  ]\n  const [open, setOpen] = React.useState(false)\n  const galleryImages = cloneDeep(images)\n  return (\n    <>\n      <Gallery photos={galleryImages} onClick={() => setOpen(true)} />\n      <GooglePhoto open={open} src={images} onClose={() => setOpen(false)} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Gallery,
      cloneDeep,
      GooglePhoto,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        // https://unsplash.com/collections/589374/textures
        const images = [{
          src: 'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 666,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 844
        }];
        const [open, setOpen] = React.useState(false);
        const galleryImages = cloneDeep(images);
        return <>
        <Gallery photos={galleryImages} onClick={() => setOpen(true)} mdxType="Gallery" />
        <GooglePhoto open={open} src={images} onClose={() => setOpen(false)} mdxType="GooglePhoto" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "fullscreen-example"
    }}>{`Fullscreen example`}</h3>
    <p>{`If you want the slideshow to open in fullscreen mode, add the `}<inlineCode parentName="p">{`fullscreen`}</inlineCode>{` prop.`}</p>
    <Playground __position={2} __code={'() => {\n  // https://unsplash.com/collections/589374/textures\n  const images = [\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 666,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 844,\n    },\n  ]\n  const [open, setOpen] = React.useState(false)\n  const galleryImages = cloneDeep(images)\n  return (\n    <>\n      <Gallery photos={galleryImages} onClick={() => setOpen(true)} />\n      <GooglePhoto\n        fullscreen\n        open={open}\n        src={images}\n        onClose={() => setOpen(false)}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Gallery,
      cloneDeep,
      GooglePhoto,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        // https://unsplash.com/collections/589374/textures
        const images = [{
          src: 'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 666,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 844
        }];
        const [open, setOpen] = React.useState(false);
        const galleryImages = cloneDeep(images);
        return <>
        <Gallery photos={galleryImages} onClick={() => setOpen(true)} mdxType="Gallery" />
        <GooglePhoto fullscreen open={open} src={images} onClose={() => setOpen(false)} mdxType="GooglePhoto" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "managed-state"
    }}>{`Managed state`}</h3>
    <p>{`By default react-google-photo manage it's own state internally. If you want to manage the state yourself, you need to use the `}<inlineCode parentName="p">{`srcIndex`}</inlineCode>{` and `}<inlineCode parentName="p">{`onChangeIndex`}</inlineCode>{` props. `}<inlineCode parentName="p">{`srcIndex`}</inlineCode>{` represent the index of the current image displayed, and `}<inlineCode parentName="p">{`onChangeIndex`}</inlineCode>{` will be called when the user request to see the next or previous image (either with the keyboard or clicking on the arrows).`}</p>
    <Playground __position={3} __code={'() => {\n  // https://unsplash.com/collections/589374/textures\n  const images = [\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 666,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 844,\n    },\n  ]\n  const [open, setOpen] = React.useState(false)\n  const [index, setIndex] = React.useState(0)\n  const galleryImages = cloneDeep(images)\n  return (\n    <>\n      <Gallery\n        photos={galleryImages}\n        onClick={(_, data) => {\n          setIndex(data.index)\n          setOpen(true)\n        }}\n      />\n      <GooglePhoto\n        open={open}\n        src={images}\n        srcIndex={index}\n        onChangeIndex={newIndex => setIndex(newIndex)}\n        onClose={() => setOpen(false)}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Gallery,
      cloneDeep,
      GooglePhoto,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        // https://unsplash.com/collections/589374/textures
        const images = [{
          src: 'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 666,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 844
        }];
        const [open, setOpen] = React.useState(false);
        const [index, setIndex] = React.useState(0);
        const galleryImages = cloneDeep(images);
        return <>
        <Gallery photos={galleryImages} onClick={(_, data) => {
            setIndex(data.index);
            setOpen(true);
          }} mdxType="Gallery" />
        <GooglePhoto open={open} src={images} srcIndex={index} onChangeIndex={newIndex => setIndex(newIndex)} onClose={() => setOpen(false)} mdxType="GooglePhoto" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "custom-animation"
    }}>{`Custom animation`}</h3>

    <p>{`If you want to change the default animation, you can do so by creating your own css animation. For example if you add the following css to your app you will get the following result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* examples/custom-animation.css */
@keyframes customEnterAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
`}</code></pre>
    <Playground __position={4} __code={'() => {\n  // import \'./examples/custom-animation.css\';\n  const images = [\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 666,\n      height: 1000,\n    },\n    {\n      src:\n        \'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D\',\n      width: 1500,\n      height: 844,\n    },\n  ]\n  const [open, setOpen] = React.useState(false)\n  const galleryImages = cloneDeep(images)\n  return (\n    <>\n      <Gallery photos={galleryImages} onClick={() => setOpen(true)} />\n      <GooglePhoto\n        open={open}\n        src={images}\n        classNames={{\n          animationIn: \'customEnterAnimation\',\n          animationOut: \'customLeaveAnimation\',\n        }}\n        animationDuration={500}\n        onClose={() => setOpen(false)}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Gallery,
      cloneDeep,
      GooglePhoto,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        // import './examples/custom-animation.css';
        const images = [{
          src: 'https://images.unsplash.com/photo-1509420316987-d27b02f81864?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1509641498745-13c26fd1ed89?dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 666,
          height: 1000
        }, {
          src: 'https://images.unsplash.com/photo-1491146179969-d674118945ff?dpr=1&auto=format&fit=crop&w=1500&q=80&cs=tinysrgb&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D',
          width: 1500,
          height: 844
        }];
        const [open, setOpen] = React.useState(false);
        const galleryImages = cloneDeep(images);
        return <>
        <Gallery photos={galleryImages} onClick={() => setOpen(true)} mdxType="Gallery" />
        <GooglePhoto open={open} src={images} classNames={{
            animationIn: 'customEnterAnimation',
            animationOut: 'customLeaveAnimation'
          }} animationDuration={500} onClose={() => setOpen(false)} mdxType="GooglePhoto" />
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "license"
    }}>{`License`}</h2>
    <p>{`MIT © `}<a parentName="p" {...{
        "href": "https://www.leopradel.com/"
      }}>{`Léo Pradel`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      